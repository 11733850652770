import './App.css';
import './maintenance.css';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    setTimeout(() => {
      document.location.reload();
    }, 30000);
  }, []);


  return (
      <div class="App contain">
        <section class="section-maintenance">
          <div class="container-maintenance">
            <img src="/library/beta.png" alt="" />
            <h1>The site is temporary under maintenance, due to network issue from our provider...</h1>
            <p>It will be back in a few minutes. You can go there to check status about Privio</p>
            <a href="https://status.privio.eu" title="Nous contacter" class="dim-children">
                <i class="icon-mail"></i>
                <span class="txt-icon">status.privio.eu</span>
            </a>
          </div>
        </section>
      </div>
  );
}

export default App;
